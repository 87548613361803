import * as React from "react"
import { Link } from "gatsby"
import { FeaturedArticlesStyles } from "./FeaturedArticleStyles"
import FeaturedArticle from "./FeaturedArticle"
import useFeaturedArticle from "../../hooks/use-featured-article"
import Button from "../Button/Button"

const Features = ({ title, introduction }) => {
  const featuredArticle = useFeaturedArticle()

  return (
    <FeaturedArticlesStyles className="section">
      {title || introduction ? (
        <div className="container container__tight">
          <div className="intro__area">
            {title && <h2>{title}</h2>}
            {introduction && <p>{introduction}</p>}
          </div>
        </div>
      ) : null}

      <div className="container container__tight container__scroll">
        {featuredArticle.map((node, index) => {
          return <FeaturedArticle feature={node} key={index} />
        })}
      </div>
      <div className="container container__tight learn__more">
        <Button as={Link} to="/articles" text="All Articles" />
      </div>
    </FeaturedArticlesStyles>
  )
}

export default Features
