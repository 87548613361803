import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
// import Features from "../components/Features/Features"
import FeaturedArticles from "../components/FeaturedArticles/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return ( 
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Welcome to MMA Explained"
          subTitle="This is a resource to help you on your MMA journey."
        />
        <BasicTextModule
          title="A website dedicated to understanding what goes inside the cage."
          content="Most of the articles here are geared towards being accessible for new viewers.
          The articles aim to shed light on the techniques, tactics, and strategies of MMA fighters.
          Reading this will help you understand and appreciate the great sport of Mixed Martial Arts."
          link="/articles"
          linkText="View Articles"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        {/* <Features
          title="Featured Guides."
          introduction="Take a look at the guides on the most important aspects of MMA."
        /> */}
        <FeaturedArticles
          title="Featured Articles."
          introduction="Take a look at the articles on the most important aspects of MMA."
        />
        <LatestPosts
          title="The Latest Articles"
          introduction="My most recent articles and FAQs about MMA."
        />
      </Layout>
    </>
  )
}

export default Index
