import * as React from "react"
import Button from "../Button/Button"
import { PostItemStyles } from "./PostStyles"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FeaturedArticleStyles } from "../FeaturedArticles/FeaturedArticleStyles"

const BlogItem = ({ node }, key) => {
  const { title, gatsbyPath, introduction, createdAt, headerImage } = node
  const image = getImage(headerImage)
  return (
    <PostItemStyles key={key} to={gatsbyPath} >
          <div className="blogitem__content">
            <GatsbyImage
                    className="blogitem__img"
                    image={image}
                    alt="Blog Image"
                  />
            </div>
        
          <h4>{title}</h4>
          {introduction && <p>{introduction}</p>}
      


        <div className="blogitem__meta">
          <Button as="span" text="Read More" arrow={true} />
          <p>{createdAt}</p>
        </div>

    </PostItemStyles>


  //   <FeaturedArticleStyles>
  //   <Link to={gatsbyPath}>
  //     <GatsbyImage
  //       className="features__item--img"
  //       image={image}
  //       alt="Product Image"
  //     />
  //     {title && introduction && (
  //       <div className="features__item--content">
  //         {title && <h4>{title}</h4>}
  //         {introduction && <p>{introduction}</p>}
  //         <Button text="Read More" as="span" arrow={true} />
  //       </div>
  //     )}
  //   </Link>
  // </FeaturedArticleStyles>
  )
}

export default BlogItem
